<!--
 * @Descripttion: 关于页面
 * @Author: 张明尧
 * @Date: 2021-02-24 15:26:46
 * @LastEditTime: 2021-03-18 17:58:14
-->
<template>
  <div class="about-div">
    <div class="box_slot">
      <div class="slot_left">
        <div class="title_o">
          企业简介
        </div>
        <div class="con_data">
          <p>
            北京凯特伟业科技有限公司，是一家专门从事企业管理咨询和企业信息化落地服务的信息技术企业。多年来，凯特一直为中石油、中石化在内的央企、国企等大型集团性企业提供企业管理咨询和企业信息化落地服务，凭借专业的技术能力和优质完善的服务，获得了客户的一致好评与行业的高度认可。
          </p>
          <p>
            目前，公司主要产品包括JEPaaS开发平台、蒜瓣
            SCRM、企业大脑等，并提供定制开发和SaaS服务。
          </p>
        </div>
      </div>
      <div class="slot_right">
        <!-- <baidu-map
          class="map bm-view"
          :center="{lng: 116.3849897961, lat: 39.9773611119}"
          :scroll-wheel-zoom="true"
          :zoom="13"
        >
          <bm-marker
            :position="{lng: 116.3849897961, lat: 39.9775}"
            :dragging="true"
            animation="BMAP_ANIMATION_BOUNCE"
          />
        </baidu-map> -->
        <div class="map-view">
          <img
            :src="gsMap"
            alt=""
            @click="
              openHref(
                'https://map.baidu.com/search/%E9%87%91%E6%BE%B3%E5%9B%BD%E9%99%85/@12956001.034221955,4834880.465,19z?querytype=s&da_src=shareurl&wd=%E9%87%91%E6%BE%B3%E5%9B%BD%E9%99%85&c=131&src=0&wd2=%E5%8C%97%E4%BA%AC%E5%B8%82%E6%B5%B7%E6%B7%80%E5%8C%BA&pn=0&sug=1&l=13&b=(12928334,4823103;12989774,4852831)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=9fbfeef2ed509a44ede4a568&device_ratio=1'
              )
            "
          />
        </div>
      </div>
    </div>
    <div class="about-div-mt">
      <div class="about-div-title text-center">
        媒体报道
      </div>
      <div>
        <div v-for="(mt, _mt) in mt_list" :key="_mt" class="about-mt-list">
          <div class="about-mt-list-img">
            <img :src="mt.img" alt="" />
          </div>
          <div class="about-mt-text">
            <div class="about-mt-text-title">
              {{ mt.title }}
            </div>
            <div class="about-mt-text-main">
              {{ mt.main }}
            </div>
            <div @click="openHref(mt.href)" class="about-mt-text-btn">
              详情
              <i
                style="font-size: 12px;margin-left: 10px"
                class="jeicon jeicon-angle-right"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about-div-team">
      <div class="about-div-title text-center">
        团队简介
      </div>
      <div>
        <div class="about-team-list">
          <div class="about-team-list-img">
            <img src="../../assets/ktdn/gy/img_team.png" alt="" />
          </div>
          <div class="about-mt-text">
            <div class="about-mt-text-main">
              JEPaaS隶属北京凯特伟业科技有限公司。作为凯特科技推出的核心产品，JEPaaS的诞生不是偶然，
              而是经过10年呕心沥血的努力研发而成。
              10年来，JEPaaS一直专注于为各行业、各类型企业提供信息化落地与数字化升级服务。
              经过十多年的发展，JEPaaS商用化程度及性能水平与国际品牌同类型产品拥有同等竞争力，具备了持续创新能力与市场竞争力。
            </div>
            <div class="about-mt-text-main margin-top-20">
              在信息化管理成为主流，简约而轻便的SaaS云服务备受青睐的当下。JEPaaS团队厚积薄发，向SaaS市场延伸。
              凭借十多年的技术积累和经验沉淀，JEPaaS在SaaS领域可谓是如鱼得水，
              游刃有余。研发的“轻云蒜瓣”系列企业管理应用系统，
              以蒜瓣SCRM为代表，率先进军SaaS 市场，获得了十分热烈的市场反应。
            </div>
            <div class="about-mt-text-main margin-top-20">
              目前，JEPaaS研发团队达百余人。无论从平台支持，
              还是到升级维护，以及进军SaaS市场，都有着强大而深厚的基础。让SaaS开发变得更快捷、更容易，是JEPaaS始终坚持的信念。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box_s">
      <div class="title_s">
        发展历程
      </div>
      <div class="box_lin">
        <div class="list_lin">
          <div class="lin_con">
            <div class="dian">
              <div class="box_listr dian_list">
                <div class="list_title">
                  2019年
                </div>
                <div class="list_data">
                  轻云蒜瓣CRM、OA、MES、EAM、进销存、物业云、<br />
                  工业云等基于JEPaaS而孵化的SaaS产品逐一推向市场，<br />
                  为企业带来巨大的商业前景。<br />
                </div>
              </div>
            </div>
          </div>
          <div class="lin_con">
            <div class="dian">
              <div class="box_listl dian_list_left">
                <div class="list_title">
                  2018年
                </div>
                <div class="list_data">
                  发布7.0产品，命名为JEPaaS（大象）<br />
                  追求帮助传统ISV公司完成SaaS产品落地与公司转型。<br />
                  定位:专为SaaS而生的PaaS平台<br />
                </div>
              </div>
            </div>
          </div>
          <div class="lin_con">
            <div class="dian">
              <div class="box_listr dian_list">
                <div class="list_title">
                  2017年
                </div>
                <div class="list_data">
                  发布5.0产品命名为JEPLUS（大象）<br />
                  追求支持大集团高并发场景的应用<br />
                  定位：大集群与服务华场景下的快速开发平台<br />
                </div>
              </div>
            </div>
          </div>
          <div class="lin_con">
            <div class="dian">
              <div class="box_listl dian_list_left">
                <div class="list_title">
                  2014年
                </div>
                <div class="list_data">
                  发布3.0产品命名为JEPF（大象）<br />
                  追求移动端应用的可配性。<br />
                  定位：快速开发平台<br />
                </div>
              </div>
            </div>
          </div>
          <div class="lin_con">
            <div class="dian">
              <div class="box_listr dian_list">
                <div class="list_title">
                  2011年
                </div>
                <div class="list_data">
                  发布2.0产品命名为Pcat（波斯猫）<br />
                  追求敏捷、高效、优雅的用户体验。<br />
                  定位：快速开发平台<br />
                </div>
              </div>
            </div>
          </div>
          <div class="lin_con">
            <div class="dian">
              <div class="box_listl dian_list_left">
                <div class="list_title">
                  2008年
                </div>
                <div class="list_data">
                  发布1.0产品，命名为Ant（蚂蚁）<br />
                  追求团队协同快速开发，敏捷交付。<br />
                  定位：快速开发平台<br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about-hykd">
      <div class="about-div-title text-center margin-top-100">
        行业肯定
      </div>
      <div class="about-hykd-main">
        <div
          :style="hykd_list_data.width && `width: ${hykd_list_data.width}`"
          v-for="(hykd_list_data, _hykd_list) in hykd_list"
          :key="_hykd_list"
        >
          <div
            :style="
              hykd_list_data.height && `height: ${hykd_list_data.height}px;`
            "
            class="about-hykd-main-img"
          >
            <img :src="hykd_list_data.img" alt="" />
          </div>
        </div>
        <div class="about-hykd-main-text">
          <div
            class="about-hykd-main-text-class"
            v-for="(hykd_texts_data, _hykd_texts) in hykd_texts"
            :key="_hykd_texts"
          >
            {{ hykd_texts_data }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hykd_texts: [
        "JEPaaS平台获评“2016年度能源行业优秀软件产品”",
        "JEPaaS平台获评“2016年度电力行业优秀软件产品”",
        "2017年，JEPaaS CEO云凤程被评为“信息产业年度创新人物”",
        "2017年，凯特公司荣获中国产业与创新互联网大会“最具推动力企业奖”",
        "JEPaaS获评“2017年度北京市优秀软件产品”",
        "JEPaaS荣获“2018年度企业数字化转型最佳解决方案奖”",
        "JEPaaS荣获“2018年度金融IT服务商优秀解决方案奖”",
        "JEPaaS获评“年度最具投资价值PaaS平台”",
        "JEPaaS获评“年度最具竞争力SaaS提供商”",
      ],
      hykd_list: [
        {
          img: require("../../assets/ktdn/hy/hy1.png"),
          width: "30%",
        },
        {
          img: require("../../assets/ktdn/hy/hy2.png"),
          width: "15%",
        },
        {
          img: require("../../assets/ktdn/hy/hy3.png"),
          width: "30%",
        },
        {
          img: require("../../assets/ktdn/hy/hy4.png"),
          width: "15%",
        },
        {
          img: require("../../assets/ktdn/hy/hy5.png"),
          width: "38%",
        },
        {
          img: require("../../assets/ktdn/hy/hy6.png"),
          width: "38%",
        },
        {
          img: require("../../assets/ktdn/hy/hy7.png"),
          width: "20%",
        },
        {
          img: require("../../assets/ktdn/hy/hy8.png"),
          width: "33%",
        },
        {
          img: require("../../assets/ktdn/hy/hy9.png"),
          width: "65%",
        },
      ],
      mt_list: [
        {
          img: require("../../assets/ktdn/gy/media_1.png"),
          title: "凯特伟业CEO云凤程荣获2019中国数字中台年度创新人物",
          main:
            "2019年11月14日，由中国软件网主办，海比研究和光明网联合主办的第五届中国企业服务年会在北京香格里拉饭店圆满落幕。会上，凯特伟业CEO云凤程荣获《2019中国数字中台年度创新人物》称号，以表彰他在企业信息化落地中的不断耕耘与创新。",
          href: "http://www.soft6.com/news/201911/26/360220.html",
        },
        {
          img: require("../../assets/ktdn/gy/media_2.png"),
          title: "专访凯特伟业CEO云凤程：实在人做实干型产品",
          main:
            "市场上有这么一种现象：一些公司尤其是上市公司内部孵化的某些技术部门在独立对外提供服务后总能做到闷声发大财，原因何在？因为这些公司经过内部多年的实践验证，所以他们相较于一些创业公司更懂业务，也更了解用户实际需求。然而，这些公司也存在诸如行业局限性等问题。",
          href: "http://www.soft6.com/news/201911/26/360220.html",
        },
        {
          img: require("../../assets/ktdn/gy/media_3.png"),
          title: "凯特伟业CEO云凤程：打造专为SaaS而生的PaaS平台",
          main:
            "当IaaS寡头竞争格局已经基本落定，PaaS正在成为云计算市场的新变量。作为一种服务模式，PaaS可以推进SaaS的发展，提高Web平台上可利用的资源数量。在业内看来，SaaS市场规模虽然庞大，但增速最快的却是PaaS市场，也因此PaaS成为各大企业角逐未来的重要阵营。",
          href: "http://www.soft6.com/news/201911/26/360220.html",
        },
      ],
      gsMap: require("../../assets/ktdn/map.png"), //公司地图
    };
  },
  methods: {
    openHref(url) {
      if (!url) return;
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.about-div {
  min-height: 100vh;
  color: #3f3f3f;
  .text-center {
    text-align: center;
  }
  .about-div-team {
    width: 1400px;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 100px;
    .about-team-list {
      margin-top: 60px;
      display: flex;
      .about-mt-text-main {
        text-indent: 2em;
        margin-left: 60px;
        line-height: 1.5;
      }
      .about-team-list-img {
        img {
          width: 600px;
          height: auto;
        }
      }
    }
  }
  .about-div-mt {
    width: 1400px;
    margin: 0 auto;
    padding-bottom: 40px;
    border-bottom: 1px dotted rgba(204, 204, 204, 1);
    .about-mt-list {
      margin: 60px 0;
      display: flex;
      .about-mt-list-img {
        img {
          width: 350px;
          height: auto;
        }
      }
      .about-mt-text {
        width: 900px;
        margin-left: 100px;
        .about-mt-text-title {
          font-size: 16px;
          font-weight: 600;
        }
        .about-mt-text-main {
          margin-top: 20px;
          font-size: 14px;
          line-height: 1.8;
        }
        .about-mt-text-btn {
          width: 180px;
          font-size: 16px;
          padding: 6px 0;
          margin-top: 20px;
          background: #ffffff;
          border: 1px solid #ccc;
          color: #3f3f3f;
          text-align: center;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:hover {
            background: #0358fd;
            color: #ffffff;
            border-color: #0358fd;
            transition: 0.2s linear;
          }
        }
      }
    }
  }
  .about-div-title {
    font-size: 35px;
    font-weight: bold;
  }
  .box_slot {
    width: 1400px;
    text-align: center;
    margin: 90px auto;
    border-bottom: 1px dotted rgba(204, 204, 204, 1);
    padding-bottom: 60px;
    display: flex;
    justify-content: space-between;
    .slot_right {
      display: flex;
      align-items: center;
      .map-view {
        height: 370px;
        img {
          height: 100%;
          object-fit: cover;
          cursor: pointer;
        }
      }
    }
    .slot_left {
      width: 560px;
      .title_o {
        text-align: left;
        font-size: 35px;
        font-weight: bold;
        color: rgba(0, 0, 0, 1);
        padding: 0px 0 60px 0;
      }
      .con_data {
        text-align: left;
        font-size: 15px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 2;
        > p {
          text-indent: 2em;
          margin: 0;
        }
      }
      .hzgy {
        width: 140px !important;
        height: 50px !important;
        line-height: 50px !important;
        border-radius: 6px;
        margin: 0 !important;
        margin-top: 20px !important;
      }
    }
    .bm-view {
      width: 650px;
      height: 370px;
    }
  }
  .box_s {
    text-align: center;
    width: 100%;
    height: 1186px;
    background: rgba(236, 243, 249, 1);
    position: relative;
    .title_s {
      font-size: 40px;
      font-weight: bold;
      color: rgba(0, 0, 0, 1);
      padding: 120px 0 80px 0;
      margin-bottom: 30px;
    }
    .box_listl {
      width: 540px;
      position: absolute;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      // padding: 30px 70px;
      height: 200px;
      padding: 20px 70px;
    }
    .box_listl:before {
      content: "";
      display: block;
      width: 0px;
      height: 0px;
      border: 20px solid transparent;
      border-left: 30px solid transparent;
      border-left-color: rgba(255, 255, 255, 1);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 100%;
    }
    .box_listr {
      width: 540px;
      position: absolute;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      height: 200px;
      padding: 20px 50px;
    }
    .box_listr:before {
      content: "";
      display: block;
      width: 0px;
      height: 0px;
      border: 20px solid transparent;
      border-right: 30px solid transparent;
      border-right-color: rgba(255, 255, 255, 1);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 100%;
    }
    .list_title {
      font-size: 24px;
      font-weight: bold;
      color: rgba(255, 48, 65, 1);
      padding-bottom: 5px;
      text-align: left;
    }
    .list_data {
      font-size: 14px;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 30px;
      text-align: left;
    }
    .box_lin {
      width: 3px;
      height: 830px;
      background-color: rgba(204, 204, 204, 1);
      position: absolute;
      top: 266px;
      left: 50%;
      .list_lin {
        height: 830px;
        margin: 0 auto;
        margin-left: -12px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .lin_con {
          text-align: center;
          .dian {
            display: block;
            width: 30px;
            height: 30px;
            background: rgba(255, 48, 65, 1);
            border: 4px solid rgba(255, 255, 255, 1);
            box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
            border-radius: 50%;
            position: relative;
            .dian_list_left {
              left: -580px;
              top: -90px;
            }
            .dian_list {
              left: 60px;
              top: -90px;
            }
          }
        }
      }
    }
  }
  .about-hykd {
    .about-hykd-main {
      width: 1400px;
      margin: 60px auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .about-hykd-main-text {
        margin-top: 60px;
        margin-bottom: 120px;
        background: #ecf3f9;
        width: 100%;
        padding: 80px 0;
        border-radius: 6px;
        .about-hykd-main-text-class {
          text-align: center;
          line-height: 3;
          color: #3f3f3f;
          font-size: 16px;
        }
      }
      .about-hykd-main-img {
        padding: 20px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        // height: 320px;
        margin-top: 24px;
        img {
          height: auto;
          width: 100%;
        }
      }
    }
  }
}
</style>
